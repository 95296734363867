<template>
  <div>
    <div>
      <a href="#" style="text-decoration:none;color: #00ffc4;" @click="show = !show">
        <div
            style="width: 4vh;height: 3vh;right: 0;top: 17%;background: black;position: fixed;border-radius: 0 5% 5% 0;z-index: 99"
            >
          <img v-if="!show" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/map_left.png"
               style="width: 80%;line-height: 3vh" alt="#">
          <img v-if="show" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/map_right.png"
               style="width: 80%;line-height: 3vh" alt="#">
        </div>
      </a>
    </div>

    <transition>
      <div v-if="show" id="map_right_div">
        <div style="width: 100%;">
          <div style="height: 4vh;color: #00ffc4;font-size: 1.5rem;text-align: center;font-weight: bolder">
            {{ title_month }}月份高风险工点
          </div>
          <div id="alldiv2">

            <div style="width: 25%;border-right:3px solid #00ffc4;">
              所属工区
            </div>
            <div style="width: 25%;border-right:3px solid #00ffc4">
              工点名称
            </div>
            <div style="width: 35%;border-right:3px solid #00ffc4">
              位置
            </div>
            <div style="width: 35%;border-right:3px solid #00ffc4">
              风险名称
            </div>
          </div>
          <div style="height: 50vh;overflow: hidden;width: 100%">
            <vue3-seamless-scroll :list="dangers" class="scroll" :hover=stopFalg step="0.2">
              <div class="item" v-for="(item, index) in dangers" :key="index">
                <div id="alldiv">
                  <div style="width: 25%;border-right:3px solid #00ffc4;padding-top: 8%">
                    <a href="#" style="text-decoration:none;color: #00ffc4;" @click="show_height_danger_detail(item)">{{ item.bdstName }}</a>
                  </div>
                  <div style="width: 25%;border-right:3px solid #00ffc4;padding-top: 5%">
                    {{ item.operName }}
                  </div>
                  <div style="width: 35%;border-right:3px solid #00ffc4;padding-top: 5%">
                    {{ item.operWhere }}
                  </div>
                  <div style="width: 35%;border-right:3px solid #00ffc4;padding-top: 5%">
                    {{ item.dangerName }}
                  </div>
                </div>

              </div>
            </vue3-seamless-scroll>

          </div>
        </div>

      </div>
    </transition>
    <!--  高风险点详情-->
    <transition>
      <el-scrollbar v-if="height_danger_detail" id="height_danger_div" height="60vh">
        <div style="float: right;right: 20%;color: #FFFFFF;font-size: 20px" >
          <a href="#" style="text-decoration:none;color: #FFFFFF" @click="height_danger_detail = false">×</a>
        </div>
        <div style="color: #00ffc4;font-size: 2rem;font-weight: bolder;text-align: center;">
          {{ select_message }}
        </div>
        <div style="color: #00ffc4;font-size: 1.5rem;font-weight: bolder;margin-top: 5%">
          风险段落: {{ danger_where }}
        </div>
        <div style="color: #00ffc4;font-size: 1.5rem;font-weight: bolder;margin-top: 5%;line-height: 40px">
          管控措施: {{ danger_manage }}
        </div>
        <div style="color: #00ffc4;font-size: 1.5rem;font-weight: bolder;margin-top: 5%;line-height: 50px">
          超前地质预报: {{ danger_forecast }}
        </div>
      </el-scrollbar>
    </transition>

  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {setNowDate, setNowMonth2, setNowTimes} from "@/utils/time";
import {Vue3SeamlessScroll} from "vue3-seamless-scroll";

export default {
  name: "index",
  inject: ['reload'],
  components: {
    Vue3SeamlessScroll
  },
  data() {
    return {
      show: true,
      height_danger_detail: false,
      dangers: [{title: '还未加载'}],
      stopFalg: true,
      select_message: '',
      danger_manage: '',
      danger_forecast: '',
      danger_where: '',
      title_month: ''
    }
  },
  created() {
    this.title_month = setNowMonth2(new Date())
    this.get_height_danger()
  },
  mounted() {
    this.show_change()
    setTimeout(this.show_change, '100')
  },
  methods: {
    show_height_danger_detail(item){
      this.height_danger_detail = true
      this.select_message = item.operName + setNowMonth2(new Date()) + '月份风险源信息'
      this.danger_manage = item.dangerManage
      this.danger_forecast = item.dangerForecast
      this.danger_where = item.operWhere
    },
    get_height_danger(){
      this.axios.post('/safeRiskSource/getDangerAll', (resposne) => {
        this.dangers = resposne.obj
      },{
        month: setNowMonth2(new Date()),
        danger_level: 0
      })
    },
    show_change(){
      this.show = !this.show
    }
  }
}
</script>

<style scoped>
.v-enter-active {
  animation: bounce-in 0.5s
}

.v-leave-active {
  animation: bounce-in 0.5s reverse
}

@keyframes bounce-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

#map_right_div {
  width: 45vh;
  height: 60vh;
  right: 0;
  top: 20%;
  position: fixed;
  border-radius: 5% 0 0 5%;
  background-color: rgba(17, 16, 16, 0.5);
  z-index: 99;
  padding: 1%;
}

#alldiv2 {
  width: 98.3%;
  display: flex;
  border-top: 6px solid #00ffc4;
  border-left: 6px solid #00ffc4;
  border-right: 3px solid #00ffc4;
  border-bottom: 3px solid #00ffc4;
  color: #00ffc4;
  text-align: center;
  line-height: 50px;
  font-size: 15px;
  font-weight: bolder;
}

#alldiv {
  width: 100%;
  height: 8vh;
  display: flex;
  border-top: 3px solid #00ffc4;
  border-left: 6px solid #00ffc4;
  border-right: 3px solid #00ffc4;
  border-bottom: 3px solid #00ffc4;
  color: #00ffc4;
  text-align: center;
  font-size: 15px;
  font-weight: bolder;
  margin: 0;
  padding: 0;
}

#height_danger_div {
  width: 75vh;
  height: 60vh;
  background-color: rgba(17, 16, 16, 0.5);
  z-index: 999;
  position: fixed;
  left: 28%;
  top: 20%;
  padding: 1%;
  border-radius: 5%;
  overflow: auto;
}
.scroll {
  height: 1024px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto 0px auto;
}

.scroll .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
</style>
