<template>
  <div>
    <div style="position:relative;background: #1a232c;height: 10.5vh">
      <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/fxy_bj.png" style="width: 100%">
    </div>
    <div id="container">
      <!--  左边风险-->
      <Index_left></Index_left>
      <!--  右边风险-->
      <Index_right></Index_right>
    <!--  工点详细-->
      <transition>
        <div v-if="oper_detail_show" id="detail_oper">
          <div style="float: right;right: 20%;color: #FFFFFF;font-size: 20px" @click="oper_detail_show = false">
            ×
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
import {ElMessage} from "element-plus"
import AMapLoader from '@amap/amap-jsapi-loader'
import axios from "axios"
import Index_left from "@/views/risk_map/index_left"
import Index_right from "@/views/risk_map/index_right"
import {Decrypt} from "@/utils/secret"
import 'animate.css/animate.min.css'
import {setNowMonth2} from "@/utils/time";

window._AMapSecurityConfig = {
  securityJsCode: '5824f7e8a40226ba0fb7e5777583baa0'
}
export default {
  components: {Index_left, Index_right},
  name: "index",
  inject: ['reload'],
  data(){
    return{
      map: null,
      imgUrlRed: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/not_red.gif',
      imgUrlGreen: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/not_green.gif',
      imgUrlYellow: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/not_yellow.gif',
      imgUrlBdst: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/map_bdst.gif',
      imgUrl: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/test.png',
      bjUrl: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/fxy_bj.png',
      marker: [],
      oper_detail_msg: '',
      show_oper_detail_dia: false,
      oper_detail_show: false
    }
  },
  created() {

  },
  mounted() {
    this.initMap()
  },
  methods:{
    initMap() {
      const that = this
      AMapLoader.load({
        key: '06ffa28167a7447970868e371c029510', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.MapType', 'AMap.Geolocation', 'AMap.GeoJSON', 'AMap.GeometryUtil'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
          .then(AMap => {
            this.map = new AMap.Map('container', {
              //设置地图容器id
              viewMode: '2D', //是否为3D地图模式
              zoom: 12, //初始化地图级别
              center: [101.99356, 30.05441] //初始化地图中心点位置
            })
            this.map.setMapStyle('amap://styles/grey');
            // 添加控件
            // this.map.addControl(new AMap.Scale())
            // this.map.addControl(new AMap.MapType())
            // this.map.addControl(new AMap.Geolocation())

            // 初始化 点标记
            this.axios.get('/safeBasis/getAllMarkers', (response) => {
              let bdstarr = response.obj[0]
              let operarr = response.obj[1]
              for (let i = 0; i < response.obj2.length; i++){
                let bdstIconUrl;
                let operIconUrl;
                if (bdstarr.indexOf(response.obj2[i].bdst) >= 0){
                  bdstIconUrl = this.imgUrlRed
                }else {
                  bdstIconUrl = this.imgUrlGreen
                }
                if (operarr.indexOf(response.obj2[i].showName) >= 0){
                  operIconUrl = this.imgUrlRed
                }else {
                  operIconUrl = this.imgUrlGreen
                }
                if (response.obj2[i].showIndex === 0){
                  const show_name = response.obj2[i].showName
                  this.marker[i] = new AMap.Marker({
                    position: [Decrypt(response.obj2[i].showX), Decrypt(response.obj2[i].showY)], // 基点位置
                    icon: this.imgUrlBdst,
                    offset: new AMap.Pixel(-17, -42), // 相对于基点的偏移位置
                    label:{content: show_name},
                    title: show_name
                  })
                  this.marker[i].on('click', function (){
                    ElMessage({
                      message: show_name,
                      type: 'success'
                    })
                  })
                  this.map.add(this.marker[i])
                }
                if (response.obj2[i].showIndex === 1){
                  const show_name = response.obj2[i].showName
                  this.marker[i] = new AMap.Marker({
                    position: [Decrypt(response.obj2[i].showX), Decrypt(response.obj2[i].showY)], // 基点位置
                    icon: operIconUrl,
                    offset: new AMap.Pixel(-17, -42), // 相对于基点的偏移位置
                    label:{content: show_name},
                    title: show_name,
                    zooms: [13, 18]
                  })
                  this.marker[i].on('click', function (){
                    that.oper_detail_show = true
                  })
                  this.map.add(this.marker[i])
                }

              }
            },{
              show_index: 1,
              danger_month: setNowMonth2(new Date())
            })


            // let xyzTileLayer = new AMap.TileLayer({
            //   //图块取图地址
            //   getTileUrl: function (x, y, z) {
            //     let name = '{x}_{y}'
            //         .replace("{x}", x)
            //         .replace("{y}", y)
            //         .replace("{z}", z)
            //     ;
            //     return 'https://czsc.oss-cn-beijing.aliyuncs.com/title/' + z + '/' + name + '.png';
            //     //return 'https://www.cr12cz.cn/map/' + z + '/' + name + '.png';
            //   },
            //   zIndex: 100
            // });
            // this.map.add(xyzTileLayer)
            this.getGeoJSON(this.map)
          })
          .catch(e => {
            console.log(e)
          })



    },

    async getGeoJSON(map) {
      axios.get('https://czsc.oss-cn-beijing.aliyuncs.com/ossczsc/risk_map/cz_zheng_xian__only_2023_3_19.json').then(function (response) {
        // 放在src中的文件都会被webpack根据依赖编译，无法作为路径使用，static中的文件才可以作为路径用
        let geojson = new AMap.GeoJSON({
          geoJSON: response.data,
          // 还可以自定义getMarker和getPolyline
          getMarker: function (geojson, lnglats){
            return new AMap.Marker({
              path: lnglats,
            });
          },
          getPolyline: function (geojson, lnglats){
            return new AMap.Polyline({
              path: lnglats,
              strokeWeight: 10, // 线条宽度，默认为 1
              strokeColor: '#ff0101', // 线条颜色
            });
          },
          // getPolygon: function (geojson, lnglats) {
          //   return new AMap.Polygon({
          //     path: lnglats,
          //     fillOpacity: 1 - Math.sqrt(area / 8000000000),// 面积越大透明度越高
          //     strokeColor: 'white',
          //     fillColor: 'red'
          //   });
          // }
        });
        map.add(geojson);
      })

      axios.get('https://czsc.oss-accelerate.aliyuncs.com/ossczsc/risk_map/fu_zhu_keng_dao_only.json').then(function (response) {
        // 放在src中的文件都会被webpack根据依赖编译，无法作为路径使用，static中的文件才可以作为路径用
        let geojson = new AMap.GeoJSON({
          geoJSON: response.data,
          // 还可以自定义getMarker和getPolyline
          getMarker: function (geojson, lnglats){
            return new AMap.Marker({
              path: lnglats,
            });
          },
          getPolyline: function (geojson, lnglats){
            return new AMap.Polyline({
              path: lnglats,
              strokeWeight: 10, // 线条宽度，默认为 1
              strokeColor: '#faeb03', // 线条颜色
            });
          },
          // getPolygon: function (geojson, lnglats) {
          //   return new AMap.Polygon({
          //     path: lnglats,
          //     fillOpacity: 1 - Math.sqrt(area / 8000000000),// 面积越大透明度越高
          //     strokeColor: 'white',
          //     fillColor: 'red'
          //   });
          // }
        });
        map.add(geojson);
      })

      axios.get('https://czsc.oss-accelerate.aliyuncs.com/ossczsc/risk_map/ping_dao_only.json').then(function (response) {
        // 放在src中的文件都会被webpack根据依赖编译，无法作为路径使用，static中的文件才可以作为路径用
        let geojson = new AMap.GeoJSON({
          geoJSON: response.data,
          // 还可以自定义getMarker和getPolyline
          getMarker: function (geojson, lnglats){
            return new AMap.Marker({
              path: lnglats,
            });
          },
          getPolyline: function (geojson, lnglats){
            return new AMap.Polyline({
              path: lnglats,
              strokeWeight: 3, // 线条宽度，默认为 1
              strokeColor: '#3498db', // 线条颜色
            });
          },
          // getPolygon: function (geojson, lnglats) {
          //   return new AMap.Polygon({
          //     path: lnglats,
          //     fillOpacity: 1 - Math.sqrt(area / 8000000000),// 面积越大透明度越高
          //     strokeColor: 'white',
          //     fillColor: 'red'
          //   });
          // }
        });
        map.add(geojson);
      })
    }
  }
}
</script>

<style scoped>
  #container{
    margin-top: -0.3vh;
    width: 100%;
    height: 89.8vh;
  }

  /deep/
  .amap-marker-label{
    border: 1px solid #FFFFFF;
    background-color: #1a232c;
    color: #FFFFFF;
  }

  #detail_oper{
    color:#FFFFFF;
    font-size: 20px;
    z-index: 99;
    width: 90vh;
    height: 60vh;
    right: 25%;
    top: 20%;
    background: rgba(0, 0, 0, 0.99);
    border-radius: 5%;
    opacity: 0.8;
    position: fixed;
    padding: 1%;
  }

  .v-enter-active {
    animation: bounce-in 0.5s
  }

  .v-leave-active {
    animation: bounce-in 0.5s reverse
  }
  @keyframes bounce-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.8;
    }
  }
</style>
