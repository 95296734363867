<template>
  <div>
    <div>
      <a href="#" style="text-decoration:none;color: red;" @click="show = !show">
        <div
            style="width: 4vh;height: 3vh;top: 17%;background: black;position: fixed;border-radius: 0px 5% 5% 0px;;z-index: 99"
        >
          <img v-if="show" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/map_left.png"
               style="width: 80%;line-height: 3vh">
          <img v-if="!show" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/map_right.png"
               style="width: 80%;line-height: 3vh">
        </div>
      </a>
    </div>

    <transition>
      <div v-if="show" id="map_left_div">
        <!--  天气-->
        <div style="color: #00ffc4;display: flex;justify-content: space-between">
          <!--康定天气-->
          <div>
            <div class="weath">
              康定今日天气({{ weath_kd[6] }})
              <img :src="kd_weath_url" alt="" style="width: 60%;margin-left: 10%">
            </div>
            <div style="display: flex">
              <div class="weath_detail">
                温度:{{ weath_kd[0] }}°C
              </div>
              <div class="weath_detail" style="margin-left: 18%">
                体感温度:{{ weath_kd[1] }}°C
              </div>
            </div>
            <div style="display: flex">
              <div class="weath_detail">
                风向:{{ weath_kd[4] }}
              </div>
              <div class="weath_detail" style="margin-left: 10%">
                风级:{{ weath_kd[3] }}
              </div>
            </div>
            <div class="weath_detail">
              风速:{{ weath_kd[2] }}
            </div>
          </div>
          <!--分割线-->
          <div style="border: 1px solid #FFFFFF;"></div>
          <!--泸定天气-->
          <div style="margin-left: 2%">
            <div class="weath" style="margin-left: 4%">
              泸定今日天气({{ weath_ld[6] }})
              <img :src="ld_weath_url" alt="" style="width: 60%;margin-left: 10%">
            </div>
            <div style="display: flex">
              <div class="weath_detail">
                温度:{{ weath_ld[0] }}°C
              </div>
              <div class="weath_detail" style="margin-left: 15%">
                体感温度:{{ weath_ld[1] }}°C
              </div>
            </div>
            <div style="display: flex">
              <div class="weath_detail">
                风向:{{ weath_ld[4] }}
              </div>
              <div class="weath_detail" style="margin-left: 10%">
                风级:{{ weath_ld[3] }}
              </div>
            </div>
            <div class="weath_detail">
              风速:{{ weath_ld[2] }}
            </div>

          </div>
        </div>
        <!--分割线-->
        <div style="border: 1px solid #FFFFFF;width: 100%;margin-top: 2%"></div>
      <!--  柱状图-->
        <div class="weath_detail" style="color: #00ffc4;margin-top: 2%">
          {{ danger_month }}月份风险工点个数图
        </div>
        <div id="dangerNum" style="width: 100%;height: 30%;margin-top: -5%"></div>
        <!--分割线-->
        <div style="border: 1px solid #FFFFFF;width: 100%;margin-top: 2%"></div>
      <!--  总工点数-->
        <div style="display: flex;justify-content: space-between;margin-left: 5%">
          <div style="color: #00ffc4;margin-top: 15%;font-weight: bolder;font-size: 1.5rem">
            截至目前掌子面个数
          </div>
          <div id="all_oper_num">
            {{ opernum }}
          </div>
        </div>

      </div>
    </transition>

  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {setNowMonth2} from "@/utils/time";

export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      show: true,
      weath_kd: [],
      weath_ld: [],
      kd_weath_url: '',
      ld_weath_url: '',
      danger_month: '',
      opernum: ''
    }
  },
  created() {
    this.danger_month = setNowMonth2(new Date())
    this.get_wea()
    setTimeout(this.get_danger_num, 100)
    this.get_all_opernum()
    setInterval(this.get_wea,60*60*1000)//10分钟执行一次
  },
  methods: {
    // 获取天气
    get_wea() {
      this.axios.post2('/safeRiskSource/getWeath', (response) => {
        this.weath_kd = response.obj[0]
        this.weath_ld = response.obj[1]
        this.kd_weath_url = this.weath_kd[5]
        this.ld_weath_url = this.weath_ld[5]
      })
    },
    // 获取柱状图
    get_danger_num(){
      const myChart = this.$echarts.init(document.getElementById('dangerNum'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.get('/safeRiskSource/getDangerNum', (response) => {
        myChart.hideLoading();    //隐藏加载动画
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: ['高风险', '中风险', '低风险'],
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '工点个数',
              type: 'bar',
              barWidth: '30%',
              data: [response.obj[0], response.obj[1], response.obj[2]],
              color: {
                type: 'bar',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                }, {
                  offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                },
                  {
                    offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                  }],
                global: false // 缺省为 false
              },
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: {
                      //数值样式
                      color: '#fff',
                      fontSize: 12,
                    },
                  },
                }
              },
            },

          ]
        })
      },{
        danger_month: setNowMonth2(new Date())
      })
    },
    // 获取总工点数
    get_all_opernum(){
      this.axios.get('/operName/getOperNum', (response) => {
        this.opernum = response.obj
      })
    }
  }
}
</script>

<style scoped>
.v-enter-active {
  animation: bounce-in 0.5s
}

.v-leave-active {
  animation: bounce-in 0.5s reverse
}

@keyframes bounce-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

#map_left_div {
  width: 45vh;
  height: 60vh;
  top: 20%;
  position: fixed;
  border-radius: 0px 5% 5% 0px;
  background-color: rgba(17, 16, 16, 0.5);
  z-index: 99;
  padding: 1%;
}

.weath {
  font-size: 1.3rem;
  font-weight: bolder
}

.weath_detail {
  font-size: 1.1rem;
  font-weight: bolder
}
#all_oper_num{
  width:7vw;
  height: 12vh;
  background-color: #50b4c4;
  border-radius: 50%;
  text-align: center;
  line-height: 12vh;
  font-size: 2rem;
  color: #00ffc4;
  font-weight: bolder;
  margin-right: 20%;
  margin-top: 4%;
}
</style>
